import React, { FC } from 'react'
import { Skeleton as ANTDSkeleton } from 'antd'
import { SkeletonProps } from 'antd/lib/skeleton'
import { SkeletonStyle } from '../../../style/styled/antd/Skeleton.style'
import { SkeletonImageProps } from 'antd/lib/skeleton/Image'
import { SkeletonInputProps } from 'antd/lib/skeleton/Input'
import { SkeletonButtonProps } from 'antd/lib/skeleton/Button'

export const Skeleton: FC<SkeletonProps & React.RefAttributes<HTMLDivElement>> & {
  Image: FC<SkeletonImageProps>
  Input: FC<SkeletonInputProps>
  Button: FC<SkeletonButtonProps>
} = (props) => (
  <>
    <style jsx global>
      {SkeletonStyle}
    </style>
    <ANTDSkeleton {...props} />
  </>
)
Skeleton.Image = (props) => (
  <>
    <style jsx global>
      {SkeletonStyle}
    </style>
    <ANTDSkeleton.Image {...props} />
  </>
)
Skeleton.Input = (props) => (
  <>
    <style jsx global>
      {SkeletonStyle}
    </style>
    <ANTDSkeleton.Input {...props} />
  </>
)
Skeleton.Button = (props) => (
  <>
    <style jsx global>
      {SkeletonStyle}
    </style>
    <ANTDSkeleton.Button {...props} />
  </>
)
