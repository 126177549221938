import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
export default function createI18n(lng: string, resources): void {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      fallbackLng: false,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })
}
