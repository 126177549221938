/* eslint-disable @typescript-eslint/ban-ts-comment */
import { X_AUTH_TOKEN } from '../utillities/CONSTANT_HEADERS'
import { COOKIES_NAME_CANDIDATE_ONBOADING, deleteCookie } from '../utillities/Cookies'
import { clearUser } from './useCurrentUser'

export const useLogout = () => {
  return () => {
    //@ts-ignore
    if (typeof window?.Gleap === 'function') {
      //@ts-ignore
      window.Gleap.clearIdentity()
    }
    deleteCookie(X_AUTH_TOKEN)
    deleteCookie(COOKIES_NAME_CANDIDATE_ONBOADING)
    // deleteCookie('_vercel_no_cache')
    return Promise.resolve(clearUser())
  }
}
export default useLogout
