import Image from 'next/image'
import { FC } from 'react'
import useIsLogin from '../../hooks/useIsLogin'
import { useChangeCandidateLanguageAsync, useTranslation } from '../../hooks/useTranslation'
import RESOURCE_IMAGE_EN_FLAG from './assets/en_flag.webp'
import RESOURCE_IMAGE_VN_FLAG from './assets/vn_flag.webp'

const ChangeLanguageDropdown: FC<{ subDropdownProps?: any }> = ({ subDropdownProps = {} }) => {
  const { currentLanguage, translate, changeLanguage } = useTranslation()
  function languageCode2Name(code: string): string {
    return { vi: 'VN', en: 'EN', jp: 'JP' }[code]
  }
  const [changeLang] = useChangeCandidateLanguageAsync()

  return (
    <div className="d-flex dropdown-language">
      <label className="box-dropdown">
        {currentLanguage && currentLanguage === 'vi' ? (
          <Image src={RESOURCE_IMAGE_VN_FLAG} alt="vn_flag" width={21} height={13} />
        ) : (
          <Image src={RESOURCE_IMAGE_EN_FLAG} alt="en_flag" width={21} height={13} />
        )}
        <span className="name">{languageCode2Name(currentLanguage)}</span>
      </label>

      <div className="sub-dropdown" {...subDropdownProps}>
        <div className="box">
          <div
            onClick={() => {
              useIsLogin() ? changeLang('vi') : changeLanguage('vi')
            }}>
            <div className="d-flex align-items-center">
              <div style={{ width: 21, height: 18 }}>
                <Image src={RESOURCE_IMAGE_VN_FLAG} alt="vn_flag" width={21} height={13} />
              </div>
              <span className="flex-one">{translate('header:language_vn')}</span>
            </div>
          </div>
          <div
            onClick={() => {
              useIsLogin() ? changeLang('en') : changeLanguage('en')
            }}>
            <div className="d-flex align-items-center">
              <div style={{ width: 21, height: 18 }}>
                <Image src={RESOURCE_IMAGE_EN_FLAG} alt="en_flag" width={21} height={13} />
              </div>
              <span className="flex-one">{translate('header:language_en')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeLanguageDropdown
