import { FC } from 'react'

const createIconComponent = (IconComponent): FC<{ className?: string; style?: any }> => ({ className, style }) => {
  return (
    <span style={style} className={`anticon anticon-share-alt text-blue ${className}`}>
      <IconComponent />
    </span>
  )
}
export default createIconComponent
