import React, { FC } from 'react'
import { Progress as ANTDProgress } from 'antd'
import { ProgressProps } from 'antd/lib/progress'
import { ProgressStyle } from '../../../style/styled/antd/Progress.style'

export const Progress: FC<ProgressProps> = (props) => (
  <>
    <style jsx global>
      {ProgressStyle}
    </style>
    <ANTDProgress {...props} />
  </>
)
