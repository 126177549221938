import Script from 'next/script'
import { useCallback, useEffect } from 'react'
import env from '../env'
import useCurrentUser from '../hooks/useCurrentUser'
const CrispChat = () => {
  const [currentUser] = useCurrentUser()
  const onScriptLoad = useCallback(() => {
    const allowUserType = process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE?.split(',').map((stri) => parseInt(stri))
    if (allowUserType.includes(1)) {
      if (currentUser && window.$crisp) {
        //   try {
        window.$crisp.push(['set', 'user:email', currentUser?.email])
        window.$crisp.push(['set', 'user:nickname', currentUser?.full_name])
        window.$crisp.push(['set', 'user:phone', currentUser?.phone_number])
        window.$crisp.push(['set', 'user:avatar', currentUser?.photo_url])
        window.$crisp.push(['set', 'session:segments', [['candidate', 'web-chat']]])
        window.$crisp.push(['set', 'session:data', [[['freec_user_id', currentUser?.id]]]])
        //   } catch (error) {
        //     Sentry.captureException(error)
        //   }
      } else {
        if (window.$crisp) {
          window.$crisp.push(['do', 'chat:close'])
        }
      }
    }
    return () => {
      // consolog aim to destroy
    }
  }, [currentUser])
  useEffect(() => {
    if (currentUser) {
      onScriptLoad()
    }
  }, [currentUser])
  return (
    <>
      <Script>
        {`
         window.$crisp = []
         window.CRISP_WEBSITE_ID = "${env.PUBLIC_CRISP_WEBSITE_ID}"
        `}
      </Script>
      <Script id="crisp-lib" src="https://client.crisp.chat/l.js" strategy="lazyOnload" onLoad={onScriptLoad} />
    </>
  )
}
export default CrispChat
