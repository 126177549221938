import { useMemo } from 'react'
import { useAmp } from 'next/amp'
import { LinkProps } from 'next/link'
import useAppRouter from '../../hooks/useAppRouter'
import { getHrefAsInRewriteContext } from '../../utillities/TranslationUtils'
import React from 'react'

export const LinkWrapper = (props: { children: React.ReactChild } & LinkProps & { hardReload?: boolean; disabled?: boolean }) => {
  const router = useAppRouter()
  const isAmp = useAmp()
  const { locale } = router
  const { href, locale: linkLocale, as, disabled } = props
  // app translation here
  // this compicated stuff to overide next intenational behavour by the concept discuss from repository below
  // https://github.com/hozana/next-translate-routes
  const asLinkContext = useMemo<{ href?: string; as?: string }>(() => getHrefAsInRewriteContext(href, linkLocale || locale) || {}, [
    href,
    linkLocale,
    locale
  ])
  return (
    <React.Fragment>
      {props.children &&
        React.Children.map(props.children, (child) => {
          // Checking isValidElement is the safe way and avoids a typescript
          // error too.
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              href: asLinkContext?.as || asLinkContext?.href || href,
              as: !isAmp ? asLinkContext?.as || asLinkContext?.href || href : null,
              disabled
            })
          }
          return child
        })}
    </React.Fragment>
  )
}
export default LinkWrapper
