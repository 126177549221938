import { useTranslation as useTranslate } from 'react-i18next'
import { useEffect } from 'react'
import useAppRouter from './useAppRouter'
import { COOKIES_NAME_LANGUAGE, setCookie } from '../utillities/Cookies'
import { createSimpleEmitter } from '../utillities/Emitter'
import { translateRouterHref } from './useTranslateLink'
import Client from '../utillities/Client'
import { API_URL_ACCOUNT } from '../utillities/APIConstant'
import { useAsyncAction } from './share/useAsyncAction'
export type TraslateFuncType = (message: string, parrams?: { [key: string]: string | number } | string, options?: any) => string
const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter()
const languageNumberMap = {
  en: 0,
  vi: 1,
  vn: 1
}
const numberLanuageMap = {
  0: 'en',
  1: 'vi'
}
export const useTranslation = (
  onLanguageChange?: () => Promise<any>
): {
  currentLanguage: string
  translate: TraslateFuncType
  changeLanguage: (code: string) => void
  switchLanguage: (swicher: { en: string; vi: string }) => string
  fromNumberToCode: (id: number) => string
  translateLink: (path: string) => string
  currentLanguageNumber: number
} => {
  const router = useAppRouter()
  const { t } = useTranslate()
  const locale = router.locale
  // const currentUser = useCurrentUser()
  const changeLanguage = (code) => {
    setCookie(COOKIES_NAME_LANGUAGE, code, 10000)
    const pathName = translateRouterHref(router.asPath.split('?')[0], code)
    const pathQuery = router.asPath.split('?')[1] ? '?' + router.asPath.split('?')[1] : ''
 
    router.push(pathName, pathName + pathQuery, { locale: code })
    emitAll()
  }

  useEffect(() => {
    if (onLanguageChange) {
      regiterCallback(onLanguageChange)
    }
    return () => {
      if (onLanguageChange) {
        unregisterCallBack(onLanguageChange)
      }
    }
  }, [])
  const translateLink = (link) => translateRouterHref(link, locale)
  return {
    currentLanguage: locale,
    translate: t,
    fromNumberToCode: (id: number) => numberLanuageMap[id],
    changeLanguage,
    switchLanguage: (swicher: { en: string; vi: string }) => {
      return locale === 'vi' ? swicher.vi : swicher.en
    },
    currentLanguageNumber: languageNumberMap[locale],
    translateLink
  }
}
export const useChangeCandidateLanguageAsync = (): [(lang: string) => Promise<any>, boolean] => {
  const { changeLanguage } = useTranslation()
  const [changeLanguageAsync, changingLanguage] = useAsyncAction((lang: string) => {
    return Client.put(API_URL_ACCOUNT, {
      data: {
        language: languageNumberMap[lang]
      }
    }).then((rs) => {
      changeLanguage(lang)
      return rs
    })
  })
  return [changeLanguageAsync, changingLanguage]
}
