import useAppRouter from '../hooks/useAppRouter'
import createI18n from '../locale/i18n'

const initPageLocale = (Component, resource) => {
  const LocaleProvider = (props) => {
    const router = useAppRouter()
    createI18n(router.locale, resource)
    return <Component {...props} />
  }
  if (Component.getInitialProps) LocaleProvider.getInitialProps = Component.getInitialProps
  if (Component.getStaticProps) LocaleProvider.getStaticProps = Component.getStaticProps
  return LocaleProvider
}

export default initPageLocale
