import React, { FC } from 'react'
import { Radio as ANTDRadio } from 'antd'
import { RadioGroupProps, RadioProps } from 'antd/lib/radio'
import { RadioButtonProps } from 'antd/lib/radio/radioButton'
import { RadioStyle } from '../../../style/styled/antd/Radio.style'

export const Radio: FC<RadioProps> & {
  Group: FC<RadioGroupProps>
  Button: FC<RadioButtonProps>
} = (props) => (
  <>
    <style jsx global>
      {RadioStyle}
    </style>
    <ANTDRadio {...props} />
  </>
)
Radio.Group = (props: RadioGroupProps) => (
  <>
    <style jsx global>
      {RadioStyle}
    </style>
    <ANTDRadio.Group {...props} />
  </>
)
Radio.Button = ANTDRadio.Button
