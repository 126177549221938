import config from '../../../config'
import { match, compile } from 'path-to-regexp'
import { LINK_URL_SEARCH, LINK_URL_SEARCH_VN } from './LinkURL'

const isMatch = (pattern: string, path: string) => {
  if (pattern === '*') {
    return true
  }
  return match(pattern)(path)
}
// app translation here
// this compicated stuff to overide next intenational behaviour by the concept discuss from the repository below
// https://github.com/hozana/next-translate-routes
export const getLocaleInRewriteContext = (asPath: string, nextLocale: string) => {
  const pathWithoutQuery = asPath?.split('?')[0]
  const matchViRewriteRule = config.routing.translates.find((item) => !item.pathname && isMatch(item.vi, pathWithoutQuery))

  const matchEnRewriteRule = config.routing.translates.find((item) => !item.pathname && isMatch(item.en, pathWithoutQuery))
  const isStatic = matchViRewriteRule?.static || matchEnRewriteRule?.static
  const strickLocale = nextLocale === 'eng' ? 'en' : 'vi'
  return isStatic ? strickLocale : matchViRewriteRule ? 'vi' : matchEnRewriteRule ? 'en' : strickLocale
}
export const getHrefAsInRewriteContext = (path, locale) => {
  const pathWithoutQuery = path?.split('?')[0]
  const matchRewriteRule = config.routing.translates.find((item) => isMatch(item.default, pathWithoutQuery))
  if (matchRewriteRule && locale === 'vi') {
    const matcherSource = match<any>(matchRewriteRule.en)(path)
    return {
      href: path,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      as: [LINK_URL_SEARCH, LINK_URL_SEARCH_VN].includes(matchRewriteRule.vi) ? path : compile(matchRewriteRule.vi)(matcherSource?.params)
    }
  }
  if (matchRewriteRule && locale === 'en') {
    const matcherSource = match<{}>(matchRewriteRule.en)(path)
    return {
      href: path,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      as: [LINK_URL_SEARCH, LINK_URL_SEARCH_VN].includes(matchRewriteRule.en) ? path : compile(matchRewriteRule.en)(matcherSource?.params)
    }
  }
  return
}
export const translateAsPathFromTo = (asPath: string, lang: string, toLang: string) => {
  const pathWithoutQuery = asPath?.split('?')[0]
  const matchRewriteRule = config.routing.translates.find((item) => isMatch(item[lang], pathWithoutQuery))
  if (matchRewriteRule) {
    const matcherSource = match<{}>(matchRewriteRule[lang])(asPath)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return compile(matchRewriteRule[toLang])(matcherSource?.params)
  }
  return asPath
}
