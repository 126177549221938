import { NextRouter, useRouter } from 'next/dist/client/router'
import { useCallback, useMemo } from 'react'
import { UrlObject } from 'url'
// import * as qs from 'query-string'
import { getLocaleInRewriteContext } from '../utillities/TranslationUtils'
import { translateRouterHref } from './useTranslateLink'
declare type Url = UrlObject | string
interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  usePush?: boolean
  ignoreTranslation?: boolean
}
const useAppRouter = () => {
  const router = useRouter()
  const locale = useMemo(() => getLocaleInRewriteContext(router.asPath, router.locale), [router])
  const push = useCallback(
    (url: Url, as?: Url, options?: TransitionOptions) => {
      const { locale: pushLocale, ...nonLocaleOptions } = options || ({} as TransitionOptions)
      const currentAsContext = translateRouterHref((as as string) || (url as string), pushLocale || locale)

      // if (nonLocaleOptions?.mergeQuery) {
      //   const finalAs = currentAsContext || as
      //   const [pathStr, searchString] = finalAs.toString().split('?')
      //   const currentQuery = qs.parse(searchString)
      //   const query = { ...currentQuery, ...nonLocaleOptions.mergeQuery }
      //   return router.push({ pathname: url.toString(), query }, { pathname: pathStr, query }, { ...nonLocaleOptions })
      // }

      if (!options?.usePush) {
        window.location.href = currentAsContext || (as ? as.toString() : url ? url.toString() : '')
        return Promise.resolve(true)
      }
      if (options?.ignoreTranslation) {
        return router.push(url, as, nonLocaleOptions)
      }
      return router.push(url, currentAsContext || as, nonLocaleOptions)
    },

    [router, locale]
  )
  const appRouter = useMemo<NextRouter & { push: (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean> }>(
    () => ({ ...router, locale, push }),
    [router]
  )
  return appRouter
}
export default useAppRouter
