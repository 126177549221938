import React, { FC } from 'react'
import { Layout as ANTDLayout } from 'antd'
import { BasicProps } from 'antd/lib/layout/layout'
import { SiderProps } from 'antd/lib/layout'
import { LayoutStyle } from '../../../style/styled/antd/Layout.style'

export const Layout: FC<BasicProps> & {
  Header?: FC<BasicProps>
  Footer?: React.ComponentClass<BasicProps>
  Content?: React.ComponentClass<BasicProps>
  Sider?: React.ComponentClass<SiderProps>
} = (props) => (
  <>
    <style jsx global>
      {LayoutStyle}
    </style>
    <ANTDLayout {...props} />
  </>
)
const LayoutHeader: FC<BasicProps> = (props: BasicProps) => (
  <>
    <style jsx global>
      {LayoutStyle}
    </style>
    <ANTDLayout.Header {...props} />
  </>
)
Layout.Header = LayoutHeader
Layout.Content = ANTDLayout.Content
Layout.Sider = ANTDLayout.Sider
